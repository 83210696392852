import request from "../../../../core/data/network/client/request";
import Api from "../../../../core/data/network/constants/api_routes";

export default {
  getReports: async () => {
    return request.get(Api.getReports());
  },
  getReport: async (document_id) => {
    return request.get(Api.getReport(document_id));
  },
  setFilter: async (dataFilter) => {
    return request.get(Api.multifilterReport(dataFilter));
  },
  manageReport: async (document_id, data) => {
    return request.post(Api.manageReport(document_id), data);
  },
  updateReport: async (document_id, data) => {
    return request.patch(Api.updateReport(document_id), data);
  },
  uploadFIlesReport: async (document_id, data) => {
    return request.post(Api.uploadFIlesReport(document_id), data);
  },
};

import AnnualReportServicesImpl from "./annual_report_services_impl";

export default {
  getReports: async () => {
    return AnnualReportServicesImpl.getReports();
  },
  getReport: async (document_id) => {
    return AnnualReportServicesImpl.getReport(document_id);
  },
  setFilter: async (dataFilter) => {
    return AnnualReportServicesImpl.setFilter(dataFilter);
  },
  manageReport: async (document_id, data) => {
    return AnnualReportServicesImpl.manageReport(document_id, data);
  },
  updateReport: async (document_id, data) => {
    return AnnualReportServicesImpl.updateReport(document_id, data);
  },
  uploadFIlesReport: async (document_id, data) => {
    return AnnualReportServicesImpl.uploadFIlesReport(document_id, data);
  },
};

<template>
  <div>
    <div class="flex justify-start">
      <b class="text-left text-lg mx-2 p-2 text-blue">Paso 2 de 2</b>
    </div>

    <!--  **************************************  START 2  *********************************************  -->
    <Accordion
      :open="openAccordions.acc_12"
      :disable="false"
      key="astep2"
      class="px-0 col-start-1 col-span-4"
      header="2. Requerimientos humanos y técnicos para el funcionamiento DCF"
    >
      <!-- <pre>{{ JSON.stringify(fields, null, 4) }}</pre> -->

      <BaseRadio
        ref="ria_2_radio"
        name="ria_2_radio"
        label="¿Durante el periodo reportado la EV recibió requerimientos humanos y técnicos por parte de la DCF?"
        v-model="data_form.requerimientos_humanos_tecnicos"
        :disabled="disbaledInput"
        @onChangeValueGetValue="(value) => onChangeRadio(value, 'ria_2_radio')"
        :radioOptions="options.oYesOrNot"
        required="true"
        class="col-span-2"
      />

      <div
        v-if="
          (showSteps[0].showStep && showSteps[0].isChecked) ||
          (fields && data_form.requerimientos_humanos_tecnicos == 1)
        "
      >
        <BaseTextarea
          class="col-start-1 col-span-2"
          ref="describe"
          id="describe"
          label="Describa la solicitud que hizo la DCF"
          placeholder="Mensaje"
          :disabled="disbaledInput"
          v-model="data_form.descripcion_requerimientos_humanos_tecnicos"
          maxlength="400"
        />

        <span
          class="validate-errors-inputs"
          v-if="
            errors &&
            errors.descripcion_requerimientos_humanos_tecnicos &&
            showSteps[0].isChecked != null &&
            data_form.descripcion_requerimientos_humanos_tecnicos.length === 0
          "
          >Este campo es obligatorio</span
        >

        <div class="flex space-x-4">
          <div class="w-1/2">
            <BaseDate
              ref="date_init"
              id="date-input"
              label="Fecha del requerimiento efectuado por la DCF."
              v-model="data_form.before_fecha_requerimientos_humanos_tecnicos"
              type="text"
              :value="data_form.before_fecha_requerimientos_humanos_tecnicos"
              :disabled="disbaledInput"
              placeholder="DD/MM/AAAA"
              class="col-start-1"
            />
          </div>
        </div>
        <span
          class="validate-errors-inputs"
          v-if="
            errors &&
            errors.before_fecha_requerimientos_humanos_tecnicos &&
            showSteps[0].isChecked &&
            data_form.before_fecha_requerimientos_humanos_tecnicos.length === 0
          "
          >Este campo es obligatorio</span
        >

        <BaseRadio
          ref="ria_2_1_radio"
          name="ria_2_1_radio"
          label="¿La entidad suministró lo solicitado por la DCF?"
          v-model="data_form.suministro_solicitado"
          @onChangeValueGetValue="
            (value) => onChangeRadio(value, 'ria_2_1_radio')
          "
          :disabled="disbaledInput"
          :radioOptions="options.oYesOrNot"
          required="true"
          class="col-span-2"
        />

        <span
          class="validate-errors-inputs"
          v-if="
            errors &&
            errors.suministro_solicitado &&
            data_form.suministro_solicitado.length === 0
          "
          >Este campo es obligatorio</span
        >

        <div
          v-if="
            (showSteps[1].showStep && showSteps[1].isChecked) ||
            (fields && data_form.suministro_solicitado == 1)
          "
          class="flex space-x-4"
        >
          <div class="w-1/2">
            <BaseDate
              ref="date_init"
              id="date-input"
              v-model="data_form.after_fecha_suministro_solicitado"
              :value="data_form.after_fecha_suministro_solicitado"
              label="¿Cuándo atendió la EV el requerimiento de la DCF?"
              type="text"
              :disabled="disbaledInput"
              placeholder="DD/MM/AAAA"
              class="col-start-1"
            />
          </div>
        </div>

        <span
          class="validate-errors-inputs"
          v-if="
            errors &&
            showSteps[1].isChecked != null &&
            data_form.after_fecha_suministro_solicitado == '' &&
            data_form.suministro_solicitado == 1
          "
        >
          Este campo es obligatorio.</span
        >

        <span
          class="validate-errors-inputs"
          v-if="
            errors &&
            showSteps[1].isChecked != null &&
            String(data_form.after_fecha_suministro_solicitado) <=
              String(data_form.before_fecha_requerimientos_humanos_tecnicos) &&
            data_form.after_fecha_suministro_solicitado != ''
          "
        >
          No puede ser inferior al campo: Fecha del requerimiento efectuado por
          la DCF</span
        >

        <BaseTextarea
          v-if="
            (showSteps[1].showStep && showSteps[1].isChecked) ||
            (fields && data_form.after_fecha_suministro_solicitado == 1)
          "
          class="col-start-1 col-span-2"
          v-model="data_form.descripcion_suministro_solicitado"
          ref="describe"
          id="describe"
          :disabled="disbaledInput"
          label="¿Cómo atendió la EV el requerimiento de la DCF?"
          placeholder="Mensaje"
          maxlength="1000"
        />

        <BaseTextarea
          v-if="
            (!showSteps[1].showStep && showSteps[1].isChecked) ||
            (fields && data_form.after_fecha_suministro_solicitado == 2)
          "
          class="col-start-1 col-span-2"
          v-model="data_form.descripcion_suministro_solicitado"
          ref="describe"
          id="describe"
          label="Cuál es la justificación de la entidad frente a la negativa"
          :disabled="disbaledInput"
          placeholder="Mensaje"
          maxlength="1000"
        />

        <span
          class="validate-errors-inputs"
          v-if="
            errors &&
            errors.descripcion_suministro_solicitado &&
            showSteps[1].isChecked != null &&
            data_form.descripcion_suministro_solicitado.length === 0
          "
          >Este campo es obligatorio</span
        >
      </div>
    </Accordion>
  </div>
</template>

<script>
// import { mapState } from "vuex";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import { handleConditionalInput } from "../../../../../core/utils/handleStateInputs";

export default {
  components: {
    Accordion,
  },
  props: [
    "current_step",
    "loadData",
    "disbaledInput",
    "view",
    "errors",
    "data_form",
  ],
  data() {
    return {
      paramId: null,
      fields: {},
      // data_form: {},
      showSteps: [
        { showStep: false, isChecked: false, key: "ria_2_radio" },
        { showStep: false, isChecked: false, key: "ria_2_1_radio" },
      ],
      openAccordions: {
        acc_1: true,
        acc_11: false,
        acc_12: false,
        acc_13: true,
      },
      options: {
        oYesOrNot: [
          { id: 1, name: "Sí" },
          { id: 2, name: "No" },
        ],
      },
    };
  },
  computed: {
    computedDataForm() {
      return this.data_form;
    },
  },
  async mounted() {
    // console.log(this.data_form);
  },
  methods: {
    onChangeRadio(value, key) {
      handleConditionalInput(this.showSteps, key, value);
    },
    cameBackPage(id) {
      // console.log(id);
      this.$router.push(
        `/dashboard/register-annual-report/detail/step-1/${id}`
      );
    },
  },
};
</script>
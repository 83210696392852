export const handleConditionalInput = (array, key, value) => {
  return array.map((step) => {
    if (step.key === key && value.id === 1) {
      return { ...step, isChecked: true, showStep: true };
    } else if (step.key === key && value.id === 2) {
      return { ...step, isChecked: true, showStep: false };
    } else {
      return step; // return the unmodified step if conditions are not met
    }
  });
};

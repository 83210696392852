<template>
    <div class="flex flex-wrap justify-evenly">
        <div v-if="step_query == 1" class="lg:w-1/2 md:w-full mt-2 mb-2 p-3">
            <button style="border: 1px solid #e02424; text-transform: uppercase;" type="button"
                class="w-full text-sm border-solid border-1 border-red-500 text-red-500 rounded-full py-3 px-20"
                @click="canceAction">
                cancelar
            </button>
        </div>

        <!-- 'border-blue', 'bg-white', 'text-blue', 'hover:bg-blue', 'hover:text-white' -->
        <div v-if="step_query > 1 && step_query <= 2" class="lg:w-1/2 md:w-full mt-2 mb-2 p-3">
            <button style="border: 1px solid #3366cc; text-transform: uppercase;" type="button"
                class="w-full text-sm border-solid border-1 border-blue-500 text-blue rounded-full py-3 px-20"
                @click="nextStep(step_query - 1)">
                Atrás
            </button>
        </div>

        <div v-if="step_query < 2" class="lg:w-1/2 md:w-full mt-2 mb-2 p-3">
            <button style="text-transform: uppercase;" type="button"
                class="w-full text-sm bg-blue-600 text-white rounded-full py-3 px-20" @click="nextStep(step_query + 1)">
                Siguiente
            </button>
        </div>
        <div v-if="step_query == 2" class="lg:w-1/2 md:w-full mt-2 mb-2 p-3">
            <button style="text-transform: uppercase;" type="submit"
                class="w-full text-sm bg-blue-600 text-white rounded-full py-3 px-20">
                Finalizar
            </button>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    props: ['step_query', 'canceAction', 'nextStep'],
    data() {
        return {}
    }
};
</script>